import { withFormsy } from 'formsy-react'
import React, { Component } from 'react'
import { SweetSelectFormsyProps } from './types'
import { guid } from 'core/utils'
import classNames from 'classnames'
import AFormatError from '../../common/AFormatError/AFormatError'
import AFormatLabel from '../../common/AFormatLabel/AFormatLabel'
import AsyncCreatableSweetSelect from 'shared/components/SweetSelect/AsyncCreatableSweetSelect'

class AsyncCreatableSweetSelectFormsy<OptionType = any> extends Component<SweetSelectFormsyProps<OptionType>> {
  id = guid()

  changeValue = (value: any) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.props.setValue(value)
  }

  render(): React.ReactNode {
    const { selectProps: { className, ...others } = { className: null }, labelClassName } = this.props
    const { label } = this.props
    const errorMessage = this.props.getErrorMessage()
    const value = this.props.getValue() || ''
    let required
    if (label && this.props.showRequired()) {
      required = <span className="text-danger"> *</span>
    }
    return (
      <div className="form-group">
        <AFormatLabel htmlFor={this.id} className={labelClassName}>
          {label} {required}
        </AFormatLabel>
        <AsyncCreatableSweetSelect
          id={this.id}
          value={value}
          defaultValue={value}
          className={classNames({ 'formsy-error': Boolean(errorMessage) }, className)}
          onChange={this.changeValue}
          {...others}
          formatCreateLabel={(inputValue: string) => `${inputValue}`}
        />
        {errorMessage ? <AFormatError helperText={errorMessage} /> : null}
      </div>
    )
  }
}

export default withFormsy(AsyncCreatableSweetSelectFormsy)
